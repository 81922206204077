import React from "react";
import "./style/dashboard.css";
import Collections from "../assets/icons/Collections.svg";
import Configurations from "../assets/icons/Configurations.svg";
import Payments from "../assets/icons/Payment.svg";
import Product from "../assets/icons/Product.svg";
import Order from "../assets/icons/Order.svg";
import Customer from "../assets/icons/Customer.svg";

function Dashboard(props) {
  const { tabActive, setTabActive } = props;

  const dashBoardData = [
    {
      key: "1",
      img: Configurations,
      title: "Configurations",
      desc: "Setup App's configurations",
      disabled: false,
    },
    {
      key: "2",
      img: Customer,
      title: "Customer Sync",
      desc: "Sync customer data from here",
      disabled: false,
    },
    {
      key: "3",
      img: Product,
      title: "Product Sync",
      desc: "Sync products from here",
      disabled: false,
    },
    {
      key: "4",
      img: Order,
      title: "Order Sync",
      desc: "Sync orders from here",
      disabled: false,
    },
    // {
    //   key: "5",
    //   img: Collections,
    //   title: "Collections Sync",
    //   desc: "Sync categories from here",
    //   disabled: true,
    // },
    {
      key: "5",
      img: Payments,
      title: "Payment Sync",
      desc: "Sync payments from here",
      disabled: false,
    },
    // {
    //   key: "6",
    //   img: Collections,
    //   title: "Collections Sync",
    //   desc: "Sync categories from here",
    //   disabled: true,
    // },
  ];

  const onHandleClick = (key, disabled) => {
    if (!disabled) {
      setTabActive(key);
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-6">
        {dashBoardData.map((tab, index) => (
          <div
            className={`border border-[#e4e5e6] p-6 rounded-xl  ${tab.disabled ? "disabled-card" : "cursor-pointer"
              }`}
            key={index}
            onClick={() => onHandleClick(tab.key, tab.disabled)}
          >
            <div className="text-center">
              <img
                className="max-w-100 mx-auto"
                src={tab.img}
                alt={tab.title}
              />
              <h2 className="line-1 font-semibold text-xl">{tab.title}</h2>
              <p className="text-sm font-normal">{tab.desc}</p>
              {tab.disabled && (
                <div className="coming-soon">Coming Soon</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Dashboard;

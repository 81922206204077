import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, message } from "antd";
import { ExclamationCircleFilled, EyeFilled } from "@ant-design/icons";
import "../../views/style/global.css";
import MainService from "../../services/main-service";
import { DEFAULT_TABLE_LIMIT, PriceSymbol } from "../constant/constant";
import TableComponent from "../theme/table/tableComponent";
import DetailModal from "../modal/detailModal";
import { DefaultSkeleton } from "../theme/loader/defaultSkeletonComponent";
import Loader from "../../components/Loader";
import { UserStateContext } from "../../context/UserContext";

const Orders = (props) => {
  const {
    tabActive,
    syncCustomLoader,
    setSyncCustomLoader,
    reload,
    setReload,
    searchValue,
  } = props;
  const [orderData, setOrderData] = useState([]);
  const [orderDetailData, setOrderDetailData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: DEFAULT_TABLE_LIMIT,
    total: 0,
  });
  const [orderSorter, setOrderSorter] = useState({
    field: "_id",
    order: "asc",
  });
  const { confirm } = Modal;
  const [customLoader, setCustomLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { application_id } = useContext(UserStateContext);

  const fetchOrderData = async (
    pageNumber,
    pageSize,
    field,
    order,
    searchValue
  ) => {
    setReload(false);
    setCustomLoader(true);
    try {
      const response = await MainService.getAllOrders(
        application_id,
        pageNumber,
        pageSize,
        field,
        order,
        searchValue
      );

      if (response.status === 200) {
        setOrderData(response.data.data);
        setPagination({
          ...pagination,
          current: pageNumber,
          pageSize: pageSize,
          total: response.data.total,
        });
        setCustomLoader(false);
      }
    } catch (error) {
      setCustomLoader(false);
      const { response } = error;
      message.error(response.data.message);
    }
  };

  const handleTableChange = async (paginationData, filters, sorterData) => {
    setSyncCustomLoader(true);
    let sortField;
    let sortOrder;
    if (paginationData?.sorter["orderBy"]) {
      sortField = paginationData?.sorter["orderBy"].split("|")[0];
      sortOrder = paginationData?.sorter["orderBy"].split("|")[1];
    } else {
      sortField = orderSorter.field;
      sortOrder = orderSorter.order;
    }

    setOrderSorter({ field: sortField, order: sortOrder });

    const response = await MainService.getAllOrders(
      application_id,
      paginationData.pagination.current,
      paginationData.pagination.pageSize,
      sortField,
      sortOrder,
      searchValue
    );

    if (response.status === 200) {
      setOrderData(response.data.data);
      setPagination({
        ...pagination,
        current: paginationData.pagination.current,
        pageSize: paginationData.pagination.pageSize,
        total: response.data.total,
      });
      setSyncCustomLoader(false);
    }
  };

  useEffect(() => {
    if (searchValue) {
      setPagination({ current: 1, pageSize: 10 });
    }
    if (tabActive === "4") {
      fetchOrderData(
        pagination.current,
        pagination.pageSize,
        orderSorter.field,
        orderSorter.order,
        searchValue
      );
    }
  }, [reload, tabActive, searchValue]);

  const orderListColumns = [
    {
      title: "ID",
      dataIndex: "_id",
      sorter: false,
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Total Order Value",
      dataIndex: "total_order_value",
      sorter: true,
      render: (text, record) => {
        const currencySymbol = PriceSymbol[record.currency] || '';
        return <span>{text ? `${currencySymbol} ${text}` : "-"}</span>;
      },
    },
    {
      title: "Order Date",
      dataIndex: "order_created_time",
      sorter: false,
      render: (text) => <span>{text ? text.split('T')[0] : "-"}</span>,
    },
    {
      title: "Payment Mode",
      dataIndex: "payment_mode",
      sorter: false,
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Fynd ID",
      dataIndex: "fynd_id",
      sorter: false,
      render: (text) => <span>{text ? text : "-"}</span>,
    },
    {
      title: "Odoo ID",
      dataIndex: "odoo_id",
      sorter: false,
      render: (text, record) => (
        <span>
          {text ? (
            text
          ) : (
            <Button onClick={() => syncOrderDetails(record)} className="mr-2">
              Sync
            </Button>
          )}
        </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      sorter: false,
      render: (actions, record) => getActions(record),
    },
  ];

  const showConfirm = () => {
    confirm({
      title: "Delete confirmation",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure want to delete this order?",
      onOk() {
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const fetchOrderDetails = async (record) => {
    setSyncCustomLoader(true);
    try {
      const response = await MainService.getOrderDetails(
        application_id,
        record._id
      );

      if (response.status === 200) {
        setOrderDetailData(response.data.data);
        setSyncCustomLoader(false);
        setModalOpen(true);
      }
    } catch (error) {
      setSyncCustomLoader(false);
      const { response } = error;
      message.error(response.data.message);
    }
  };

  const syncOrderDetails = async (record) => {
    setSyncCustomLoader(true);

    try {
      const response = await MainService.syncOrdersDetail(
        record.application_id,
        record._id
      );

      if (response.status === 200) {
        setSyncCustomLoader(false);
        fetchOrderData(
          pagination.current,
          pagination.pageSize,
          orderSorter.field,
          orderSorter.order,
          searchValue
        );
      }
    } catch (error) {
      setSyncCustomLoader(false);
      const { response } = error;
      message.error(response.data.message);
    }
  };

  const getActions = (record) => (
    <div className="flex items-center justify-start">
      <EyeFilled
        onClick={() => fetchOrderDetails(record)}
        className="text-gray-400 cursor-pointer mr-2"
      />
      {/* <DeleteOutlined onClick={showConfirm} className="text-gray-400 cursor-pointer" /> */}
    </div>
  );

  return (
    <div className="my-5">
      <div className="bg-white relative">
        <div className="mt-[84px]">
          {syncCustomLoader && <DefaultSkeleton />}
          {customLoader ? (
            <Loader />
          ) : (
            <>
              <TableComponent
                rowKey="order"
                columns={orderListColumns}
                dataSource={orderData || []}
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  showSizeChanger: false,
                }}
                onTableChange={handleTableChange}
              />

              <DetailModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                data={orderDetailData}
                title="View Order Details"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Orders;

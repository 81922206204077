import React, { useContext, useEffect, useState } from "react";
import Loader from "../components/Loader"; // Import Loader component
import { useNavigate } from "react-router-dom";
import "./style/auth.css";
import MainService from '../services/main-service';
import { UserStateContext } from "../context/UserContext";
import { message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Input } from 'antd';

function Auth() {
  // const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // State to handle loading
  const { application_id, setCompanyId, setUserId } = useContext(UserStateContext);

  const [formData, setFormData] = useState({
    application_id,
    odoo_password: "",
    odoo_userName: "",
    odoo_url: "",
    odoo_db_name: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    try {
      setLoading(true);
      async function isAuthenticated() {
        try {
          const res = await MainService.existOauthAccount(application_id);

          if (res.status === 200) {
            setUserId(res.data.auth)
            navigate("/dashboard");
          }
          else {
            setLoading(false);
          }
        } catch (error) {
          const { response } = error;
          //message.error(response.data.message);
          setLoading(false);
        }
      }
      isAuthenticated();
    } catch (error) {
      const { response } = error;
      message.error(response.data.message);
      setLoading(false);
    }
  }, [application_id]);

  const validateForm = () => {
    let tempErrors = {};
    
    if (!formData.odoo_url) {
      tempErrors.odoo_url = "Odoo URL is required";
    } else if (!/^https:\/\/[\w]+\.odoo\.com$/.test(formData.odoo_url)) {
      tempErrors.odoo_url =
        "Odoo URL is invalid. Format should be like https://example.odoo.com";
    }
    if (!formData.odoo_db_name) {
      tempErrors.odoo_db_name = "Database name is required";
    }
    if (!formData.odoo_userName) {
      tempErrors.odoo_userName = "Username is required";
    }
    if (!formData.odoo_password) {
      tempErrors.odoo_password = "Password is required";
    }
    return tempErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value, // Update the value of the corresponding input field
    });
    if (value) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    }
    else {

      try {
        setLoading(true); // Set loading to true when the form is submitted
        const res = await MainService.createOauth(formData);

        const data = await res.data;
        message.success("successfully authenticated");
        if (data.auth) {
          setCompanyId(data.auth.company_id)
          setUserId(data.auth.auth);
          navigate("/dashboard");
        }
      } catch (error) {
        const { response } = error;
        message.error(response.data.message);

        setLoading(false); // Ensure loading is set to false on error
      } finally {
        setFormData({
          odoo_password: "",
          odoo_userName: "",
          odoo_url: "",
          odoo_db_name: "",
        });
        setLoading(false);
      }
    }
  };

  return (
    <div className="auth-container">
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit} className="auth-form">
          <h2 className="font-semibold text-2xl text-black">
            Connect Your Store With Odoo
          </h2>
          <div>
            <div className="text-black text-sm font-normal mb-2 mt-4">
              Odoo url
            </div>
            <input
              type="text"
              name="odoo_url"
              value={formData.odoo_url}
              onChange={handleChange}
              placeholder="Write your odoo url"
              className="auth-input"
            />
            {errors.odoo_url && (
              <div className="text-red-600 text-sm mt-1">{errors.odoo_url}</div>
            )}
          </div>
          <div>
            <div className="text-black text-sm font-normal mb-2 mt-2">
              DB Name
            </div>
            <input
              type="text"
              name="odoo_db_name"
              value={formData.odoo_db_name}
              onChange={handleChange}
              placeholder="Write your database name"
              className="auth-input"
            />
            {errors.odoo_db_name && (
              <div className="text-red-600 text-sm mt-1">{errors.odoo_db_name}</div>
            )}
          </div>
          <div>
            <div className="text-black text-sm font-normal mb-2 mt-2">
              Username
            </div>
            <input
              type="text"
              name="odoo_userName"
              value={formData.odoo_userName}
              onChange={handleChange}
              placeholder="Write your username"
              className="auth-input"
            />
            {errors.odoo_userName && (
              <div className="text-red-600 text-sm mt-1">{errors.odoo_userName}</div>
            )}
          </div>
          <div>
            <div className="text-black text-sm font-normal mb-2 mt-2">
              Password
            </div>
            <Input.Password
              name="odoo_password"
              value={formData.odoo_password}
              onChange={handleChange}
              placeholder="Write your password"
              className="auth-input"
              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
            {errors.odoo_password && (
              <div className="text-red-600 text-sm mt-1">{errors.odoo_password}</div>
            )}
          </div>
          {/* <div className="message-container">{message}</div> */}
          <button type="submit" className="auth-button mt-5">
            Connect your store
          </button>
        </form>
      )}
    </div>
  );
}

export default Auth;

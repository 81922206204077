import React, { useContext, useEffect, useState } from "react";
import { UserStateContext } from "../../context/UserContext";
import Loader from "../../components/Loader";
import MainService from "../../services/main-service";
import "../../views/style/home.css";
import MappingIcon from "../theme/icon/mappingIcon";
import { Select, message } from "antd";
import RightArrowIcon from "../theme/icon/rightArrowIcon";
import { Customer_Filed, Order_Filed, Payment_Filed, Product_Filed } from "../constant/constant";
import { getCapitalizeOnefirstletter } from "../constant/helper";
import { InfoCircleOutlined } from "@ant-design/icons";

function Mapping(props) {
    const { title, setMappingComponent, mappingDes } = props;
    const { application_id } = useContext(UserStateContext);
    const [mappingLoader, setMappingLoader] = useState(false);
    const [odooFieldData, setOdooFieldData] = useState([]);
    const [errors, setErrors] = useState({});

    const [fields, setFields] = useState({
        name: '',
        email: '',
        phone: '',
        city: '',
        addressLine1: '',
        addressLine2: '',
        addressType: '',
        pincode: '',
        state: '',
        country: '',
        fynd_id: 'x_fynd_id'
    });

    const handleChange = (value, key) => {
        setFields({
            ...fields,
            [key]: value,
        });

        if (value) {
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[key];
                return updatedErrors;
            });
        }
    };

    const onMappingClose = () => {
        setMappingComponent(false)
    }

    const validateFields = () => {
        let validationErrors = {};
        Object.keys(fields).forEach(field => {
            if (!fields[field]) {
                validationErrors[field] = `${field} is required`;
            }
        });
        return validationErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const res = await MainService.creteCustomMapping(application_id, fields, title);
            if (res.status === 200) {
                message.success(res.data.message);
                setMappingComponent(false)
            }
            else {
                message.error(res.data.message);
            }
        }
    }

    const fetchCustomerFeild = async () => {
        setMappingLoader(true);
        try {
            const response = await MainService.getCustomerFeilds(application_id);

            if (response.status === 200) {
                const formattedFyndData = response.data.fyndFeild.reduce((acc, field) => {
                    acc[field.key] = field.value; // Store values in an object with keys
                    return acc;
                }, {});

                setFields(formattedFyndData);
                setOdooFieldData(response.data.odooFeild);
                setMappingLoader(false);
            }

        } catch (error) {
            setMappingLoader(false);
            const { response } = error;
            message.error(response.data.message);
        }
    };

    const fetchProductFeild = async () => {
        setMappingLoader(true);
        try {
            const response = await MainService.getProductFeilds(application_id);

            if (response.status === 200) {
                const formattedFyndData = response.data.fyndFeild.reduce((acc, field) => {
                    acc[field.key] = field.value; // Store values in an object with keys
                    return acc;
                }, {});

                setFields(formattedFyndData);
                setOdooFieldData(response.data.odooFeild);
                setMappingLoader(false);
            }

        } catch (error) {
            setMappingLoader(false);
            const { response } = error;
            message.error(response.data.message);
        }
    };

    const fetchOrderField = async () => {
        setMappingLoader(true);
        try {
            const response = await MainService.getOrderFeilds(application_id);

            if (response.status === 200) {
                const formattedFyndData = response.data.fyndField.reduce((acc, field) => {
                    acc[field.key] = field.value; // Store values in an object with keys
                    return acc;
                }, {});

                setFields(formattedFyndData);
                setOdooFieldData(response.data.odooField);
                setMappingLoader(false);
            }

        } catch (error) {
            setMappingLoader(false);
            const { response } = error;
            message.error(response.data.message);
        }
    };
    const fetchPaymentField = async () => {
        setMappingLoader(true);
        try {
            const response = await MainService.getPaymentFields(application_id);

            if (response.status === 200) {
                const formattedFyndData = response.data.fyndField.reduce((acc, field) => {
                    acc[field.key] = field.value; // Store values in an object with keys
                    return acc;
                }, {});

                setFields(formattedFyndData);
                setOdooFieldData(response.data.odooField);
                setMappingLoader(false);
            }

        } catch (error) {
            setMappingLoader(false);
            const { response } = error;
            message.error(response.data.message);
        }
    };


    useEffect(() => {
        if (application_id && title === "customer") {
            fetchCustomerFeild();
        }
        if (application_id && title === "product") {
            fetchProductFeild();
        }
        if (application_id && title === "order") {
            fetchOrderField();
        }
        if (application_id && title === "payment method") {
            fetchPaymentField();
        }
    }, []);

    return (

        <div>
            <div className="bg-white relative">
                {
                    mappingLoader ?
                        <Loader />
                        :
                        <>
                            <div className="flex justify-start items-center py-4">
                                <div className="col-4">
                                    <MappingIcon />
                                </div>
                                <div className="col-20 ml-3">
                                    <h1 className="font-semibold text-[30px] border-b-[3px] border-b-[#a90083] w-[100px]">Mapping</h1>
                                </div>
                            </div>
                            <div className="mb-4">
                                <div className="p-3 mt-2 relative border border-[#D9D9D9] rounded border-b-2 border-b-[#a90083]">
                                    <div className="flex justify-start items-center">
                                        <span className="p-3 text-xl border-r-2 border-r-[#ad0b88]"><InfoCircleOutlined /></span>
                                        <p className="pl-4 text-sm font-normal">{mappingDes}</p>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={handleSubmit} className="mapping-form">
                                <div className="max-w-[520px] mx-auto my-5 mt-9 p-5">
                                    <div className="grid grid-cols-3 gap-3  mb-5">
                                        <div className="text-base font-semibold text-center">Fynd fields</div>
                                        <div></div>
                                        <div className="text-base font-semibold text-start">Odoo fields</div>
                                    </div>
                                    <div className="flex flex-col gap-3">
                                        { title === "customer" && Customer_Filed?.map((data, index) => {
                                            return (<div key={index} className="flex justify-between items-center">
                                                <div className="flex-1">
                                                    <p className="bg-[#00000005] p-1">{getCapitalizeOnefirstletter(data?.key)}</p>
                                                </div>
                                                <RightArrowIcon className="mx-4" />
                                                <div className="flex-1">
                                                    <Select
                                                        showSearch
                                                        value={fields[data?.key]}
                                                        style={{ width: '100%' }}
                                                        allowClear
                                                        options={odooFieldData}
                                                        onChange={(value) => handleChange(value, data?.key)}
                                                        placeholder="Select field"
                                                    />
                                                    {errors[data?.key] && (
                                                        <div className="text-red-600 text-sm mt-1">
                                                            {errors[data?.key]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            )
                                        })}
                                        { title === "product" && Product_Filed?.map((data, index) => {
                                            return (<div key={index} className="flex justify-between items-center">
                                                <div className="flex-1">
                                                    <p className="bg-[#00000005] p-1">{getCapitalizeOnefirstletter(data?.key)}</p>
                                                </div>
                                                <RightArrowIcon className="mx-4" />
                                                <div className="flex-1">
                                                    <Select
                                                        showSearch
                                                        value={fields[data?.key]}
                                                        style={{ width: '100%' }}
                                                        allowClear
                                                        options={odooFieldData}
                                                        onChange={(value) => handleChange(value, data?.key)}
                                                        placeholder="Select field"
                                                    />
                                                    {errors[data?.key] && (
                                                        <div className="text-red-600 text-sm mt-1">
                                                            {errors[data?.key]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            )
                                        })}
                                        { title === "order" && Order_Filed?.map((data, index) => {
                                            return (<div key={index} className="flex justify-between items-center">
                                                <div className="flex-1">
                                                    <p className="bg-[#00000005] p-1">{getCapitalizeOnefirstletter(data?.key)}</p>
                                                </div>
                                                <RightArrowIcon className="mx-4" />
                                                <div className="flex-1">
                                                    <Select
                                                        showSearch
                                                        value={fields[data?.key]}
                                                        style={{ width: '100%' }}
                                                        allowClear
                                                        options={odooFieldData}
                                                        onChange={(value) => handleChange(value, data?.key)}
                                                        placeholder="Select field"
                                                    />
                                                    {errors[data?.key] && (
                                                        <div className="text-red-600 text-sm mt-1">
                                                            {errors[data?.key]}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            )
                                        })}
                                    { title === "payment method" && Payment_Filed?.map((data, index) => {
                                        return (<div key={index} className="flex justify-between items-center">
                                            <div className="flex-1">
                                                <p className="bg-[#00000005] p-1">{getCapitalizeOnefirstletter(data?.key)}</p>
                                            </div>
                                            <RightArrowIcon className="mx-4" />
                                            <div className="flex-1">
                                                <Select
                                                    showSearch
                                                    value={fields[data?.key]}
                                                    style={{ width: '100%' }}
                                                    allowClear
                                                    options={odooFieldData}
                                                    onChange={(value) => handleChange(value, data?.key)}
                                                    placeholder="Select field"
                                                />
                                                {errors[data?.key] && (
                                                    <div className="text-red-600 text-sm mt-1">
                                                        {errors[data?.key]}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        )
                                    })}
                                    </div>

                                    <div className="flex justify-between mt-8">
                                        <button className="bg-white text-black py-2 px-6 rounded border border-gray-500 w-[40%]" onClick={onMappingClose}>Cancle</button>
                                        <button type="submit" className="bg-[#A020F0] text-white py-2 px-6 rounded w-[40%]">Save</button>
                                    </div>
                                </div>
                            </form>
                        </>
                }
            </div >
        </div>

    );
}

export default Mapping;

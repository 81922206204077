import React, { useContext, useEffect, useState } from "react";
import Loader from "../Loader"; // Import Loader component
import { UserStateContext } from "../../context/UserContext";
import MainService from "../../services/main-service";
import { Checkbox, Tabs, Tooltip, message, Input } from "antd";
import { DefaultSkeleton } from "../theme/loader/defaultSkeletonComponent";
import InfoIcon from "../theme/icon/infoIcon";
import TabPane from "antd/es/tabs/TabPane";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import "../../views/style/global.css";

const Configuration = (props) => {
  const { tabActive, syncCustomLoader, setSyncCustomLoader } = props;
  const { application_id } = useContext(UserStateContext);
  const [customLoader, setCustomLoader] = useState(false);
  const [errors, setErrors] = useState({});
  const [configTabActive, setConfigTabActive] = useState("0");

  const [configFormData, setConfigFormData] = useState({
    application_id,
    odoo_url: "",
    odoo_db_name: "",
    odoo_userName: "",
    odoo_password: "",
    isAutoSyncCustomer: false,
    isAutoSyncProduct: false,
    isAutoSyncOrder: false,
    isAutoSyncCollection: false,
    isAutoSyncPaymentMethod: false,
  });

  const fetchOauthData = async () => {
    setCustomLoader(true);
    try {
      const response = await MainService.getOauthAccount(application_id);

      if (response.status === 200) {
        const data = response.data.data;

        setConfigFormData({
          application_id,
          odoo_url: data.odoo_url,
          odoo_db_name: data.odoo_db_name,
          odoo_userName: data.odoo_userName,
          odoo_password: "",
          isAutoSyncCustomer: data.isAutoSyncCustomer,
          isAutoSyncProduct: data.isAutoSyncProduct,
          isAutoSyncOrder: data.isAutoSyncOrder,
          isAutoSyncCollection: data.isAutoSyncCollection,
          isAutoSyncPaymentMethod: data.isAutoSyncPaymentMethod,
        });

        setCustomLoader(false);
      }
    } catch (error) {
      setCustomLoader(false);
      const { response } = error;
      message.error(response.data.message);
    }
  };
  const validateForm = () => {
    let tempErrors = {};
    if (!configFormData.odoo_url) {
      tempErrors.odoo_url = "Odoo URL is required";
    } else if (!/^https:\/\/[\w]+\.odoo\.com$/.test(configFormData.odoo_url)) {
      tempErrors.odoo_url =
        "Odoo URL is invalid. Format should be like https://example.odoo.com";
    }
    if (!configFormData.odoo_db_name) {
      tempErrors.odoo_db_name = "Database name is required";
    }
    if (!configFormData.odoo_userName) {
      tempErrors.odoo_userName = "Username is required";
    }

    return tempErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setConfigFormData({
      ...configFormData,
      [name]: value, // Update the value of the corresponding input field
    });
    if (value) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[name];
        return updatedErrors;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    }
    else {

      try {
        setSyncCustomLoader(true); // Set loading to true when the form is submitted
        const res = await MainService.updateOauth(configFormData);

        if (res.status === 200) {
          setSyncCustomLoader(false);
          message.success(res.data.message);
        }
      } catch (error) {
        const { response } = error;
        message.error(response.data.message);
        setSyncCustomLoader(false); // Ensure loading is set to false on error
      }

    }
  };

  useEffect(() => {
    if (tabActive === "1") {
      setConfigTabActive("0");
      fetchOauthData();
    }
  }, [tabActive]);

  const tabConfigList = [
    {
      key: "0",
      label: "Odoo Configuration",
    },
    {
      key: "1",
      label: "Set Default Configuration",
    },
  ];

  const tabSetConfigList = [
    { label: 'Auto Sync New Customer', value: 'isAutoSyncCustomer', isDisabled: false },
    { label: 'Auto Sync New Product', value: 'isAutoSyncProduct', isDisabled: false },
    { label: 'Auto Sync New Order', value: 'isAutoSyncOrder', isDisabled: false },
    // { label: 'Auto Sync New Collection', value: 'isAutoSyncCollection', isDisabled: true },
    { label: 'Auto Sync New Payment Method', value: 'isAutoSyncPaymentMethod', isDisabled: false },
  ];

  const onChangeConfigTab = (activeKey) => {
    setConfigTabActive(activeKey);
  };

  const onChange = (e) => {
    const { checked, id } = e.target;
    setConfigFormData({
      ...configFormData,
      [id]: checked,
    });
  };


  return (
    <div>
      {syncCustomLoader &&
        <DefaultSkeleton />
      }
      {customLoader ? (
        <Loader />
      ) :

        <Tabs activeKey={configTabActive} onChange={onChangeConfigTab}>
          {tabConfigList.map((tab) => (
            <TabPane
              key={tab.key}
              tab={
                <div className={`flex items-center`}>
                  <span className="ml-2 text-sm font-normal">{tab.label}</span>
                </div>
              }
            >
              <div className="application-container pl-6 relative">
                <form onSubmit={handleSubmit} className="form">
                  {configTabActive === "0" ?

                    <div>
                      <div className="text-black text-sm font-normal mb-2 mt-4">
                        Odoo url
                      </div>
                      <input
                        type="text"
                        name="odoo_url"
                        value={configFormData.odoo_url}
                        onChange={handleChange}
                        placeholder="Write your odoo url"
                        className="auth-input"
                      />
                      {errors.odoo_url && (
                        <div className="text-red-600 text-sm mt-1">{errors.odoo_url}</div>
                      )}

                      <div>
                        <div className="text-black text-sm font-normal mb-2 mt-2">
                          DB Name
                        </div>
                        <input
                          type="text"
                          name="odoo_db_name"
                          value={configFormData.odoo_db_name}
                          onChange={handleChange}
                          placeholder="Write your database name"
                          className="auth-input"
                        />
                        {errors.odoo_db_name && (
                          <div className="text-red-600 text-sm mt-1">{errors.odoo_db_name}</div>
                        )}
                      </div>
                      <div>
                        <div className="text-black text-sm font-normal mb-2 mt-2">
                          Username
                        </div>
                        <input
                          type="text"
                          name="odoo_userName"
                          value={configFormData.odoo_userName}
                          onChange={handleChange}
                          placeholder="Write your username"
                          className="auth-input"
                        />
                        {errors.odoo_userName && (
                          <div className="text-red-600 text-sm mt-1">{errors.odoo_userName}</div>
                        )}
                      </div>
                      <div>
                        <div className="text-black text-sm font-normal mb-2 mt-2">
                          Password
                          <div className="relative inline-block ml-2 align-middle cursor-pointer group">
                            <Tooltip placement="rightTop" title="Keep it blank if you do not want to change your password!">
                              <InfoIcon />
                            </Tooltip>
                          </div>
                        </div>
                        <Input.Password
                          name="odoo_password"
                          value={configFormData.odoo_password}
                          onChange={handleChange}
                          placeholder="Write your password"
                          className="auth-input"
                          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                        {errors.odoo_password && (
                          <div className="text-red-600 text-sm mt-1">{errors.odoo_password}</div>
                        )}
                      </div>
                    </div>
                    :
                    <div>
                      {tabSetConfigList.map((tab) => (
                        <Checkbox className="custom-checkbox w-[350px]" onChange={onChange}
                          checked={configFormData[tab.value]}
                          disabled={tab.isDisabled}
                          id={tab.value}>{tab.label}</Checkbox>
                      ))}
                    </div>

                  }
                  <button type="submit" className="auth-button mt-5">
                    Update changes
                  </button>
                </form>
              </div>

            </TabPane>
          ))
          }
        </Tabs>
      }
    </div >
  );
};
export default Configuration;
